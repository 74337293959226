<template>
  <div>
    <div class="login-box">
      <div class="login-main">
        <div class="login-left"></div>
        <div class="login-right">
          <div class="right-content">
            <div class="login-title">欢迎登录</div>
            <div class="login-form-box">
              <div class="login-form">
                <a-tabs class="cl-main" size="large" default-active-key="1">
                  <a-tab-pane key="1" tab="账号密码登录">
                    <div class="login-form-">
                      <div class="login-input-item">
                        <span class="ant-input-affix-wrapper">
                          <a-input
                            v-model="form.mobile"
                            placeholder="请输入手机号"
                          />
                        </span>
                      </div>
                      <div class="login-input-item">
                        <span class="ant-input-affix-wrapper">
                          <a-input
                            v-model="form.pwd"
                            type="password"
                            placeholder="请输入登录密码"
							@pressEnter="pwdLogin"
                          />
                        </span>
                      </div>
                      <div class="login-btn">
                        <a-button
                          type="primary"
                          shape="round"
                          block
                          size="large"
                          @click="pwdLogin"
                        >
                          登录
                        </a-button>
                      </div>
                    </div>
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="验证码登录(注册)" force-render>
                    <div class="login-form-">
                      <div class="login-input-item">
                        <span class="ant-input-affix-wrapper">
                          <a-input
                            v-model="form.mobile"
                            placeholder="请输入手机号"
                          />
                        </span>
                      </div>
                      <div class="login-input-item">
                        <span class="ant-input-affix-wrapper">
                          <a-input
                            v-model="form.code"
                            placeholder="请输入验证码"
							@pressEnter="onSubmit"
                          >
                            <a-button
                              slot="suffix"
                              type="link"
                              :disabled="codeDisabled"
                              @click="getSmsCode"
                            >
                              {{
                                codeDisabled
                                  ? second + "s后重新获取"
                                  : "获取验证码"
                              }}
                            </a-button>
                          </a-input>
                        </span>
                      </div>
                      <div class="login-btn">
                        <a-button
                          type="primary"
                          shape="round"
                          block
                          size="large"
                          @click="onSubmit"
                        >
                          登录
                        </a-button>
                      </div>
                    </div>
                  </a-tab-pane>
                </a-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        token: "",
        mobile: "",
        code: "",
        pwd: "",
      },
      codeDisabled: !1,
      second: 60,
    };
  },
  created() {
    this.getCode();
  },
  methods: {
    getCode() {
      this.$http
        .papi("platform/account/getCode")
        .then((res) => {
          this.form.token = res.code;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getSmsCode() {
      if (this.codeDisabled) {
        return;
      }
      if (this.form.mobile == "") {
        this.$message.error("请输入手机号");
      } else {
        this.$http
          .papi("platform/account/sms",{
            token: this.form.token,
            mobile: this.form.mobile
          })
          .then((res) => {
            this.codeDisabled = !0;
            let interval = setInterval(() => {
              let times = --this.second;
              this.second = times < 10 ? "0" + times : times; //小于10秒补 0
            }, 1000);
            setTimeout(() => {
              clearInterval(interval);
              this.second = 60;
              this.codeDisabled = !1;
            }, 60000);
          })
          .catch((res) => {
            console.log(res);
          });
      }
    },
    pwdLogin() {
      this.$http
        .papi("platform/account/pwdLogin", {
          mobile: this.form.mobile,
          pwd: this.form.pwd
        })
        .then((res) => {
          localStorage.setItem("account-token", res.auth_token);
          this.$router.push("/mendian");
        })
        .catch((res) => {
          console.log(res);
        });
    },
    onSubmit() {
      this.$http
        .papi("platform/account/login", {
          mobile: this.form.mobile,
          code: this.form.code,
          token:this.form.token
        })
        .then((res) => {
          localStorage.setItem("account-token", res.auth_token);
          this.$router.push("/mendian");
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style lang="less" >
.login-box {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: #fff;
  .login-main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    .login-left {
      height: 100%;
      flex: 1.5;
      background: url('../assets/image/login_left.png')
        no-repeat 0 0;
      background-size: 100% 100% cover;
      background-size: cover;
      position: relative;
      display: block;
    }
    .login-right {
      flex: 2;
      .right-content {
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .login-title {
          text-align: left;
          width: 456px;
          margin: 30px auto;
          font-family: PingFangSC-Medium;
          font-size: 30px;
          color: #111;
          letter-spacing: 0;
          margin-right: 17 px;
        }
        .login-form-box {
          width: 460px;
          height: 520px;
          overflow: hidden;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
          padding: 10px 10px 10px;
          border-radius: 15px;
          .login-form {
            margin-top: 20px;
            padding: 0 33px;
            .login-form- {
              margin-top: 20px;
              padding: 0 2px;
            }
            .login-input-item {
              padding-bottom: 36px;
              .ant-input {
                padding-left: 12px;
                background: #f7f8fa;
                border-radius: 9px;
                border: none;
                height: 60px;
                font-size: 14px;
              }
            }
            .ant-btn-round.ant-btn-lg {
              border: none;
              margin-top: 14px;
              height: 60px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
